<template>
  <div class="pages">
    <!--顶部头像-->
    <div class="headerInfo">
      <img class="headBack" src="../static/images/main/banner_null.png" width="100%"/>
      <div class="backView">
        <span class="Back" @click="back()">返回</span>
      </div>
      <div class="userInfo">
        <img v-if="user.Sex == '男'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon" :style="{border:'solid 2px #AACC03'}">
        <img v-if="user.Sex == '女'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon" :style="{border:'solid 2px #E75296'}">
        <span>{{ user.Name }}</span>
      </div>
    </div>
    <div class="info">
      <ul>
        <li>
          <em>{{ user.Height }}</em>
          <span>身高cm</span>
        </li>
        <li>
          <em>{{ user.Age }}</em>
          <span>年龄</span>
        </li>
        <li>
          <em>{{ user.Weight }}</em>
          <span>体重kg</span>
        </li>
        <li>
          <em>{{ user.Sex }}</em>
          <span>性别</span>
        </li>
      </ul>
    </div>

    <div class="fitnessRecord">
      <div class="title">
        测评记录
      </div>
      <ul class="recordEmpty" v-if="fitnessRecordList.length <1">
        测评记录空...
      </ul>

      <ul class="record" v-if="fitnessRecordList.length >0">
        <li v-for="(fitItem,index) in fitnessRecordList" @click="toFitnessDetail(fitItem)">
          <div class="column1">{{ index+1 }}</div>
          <div class="column2">
            {{ fitItem.ShopName.slice(5) }}
          </div>
          <div class="columnSplit"></div>
          <div class="column3">
            <span>{{ fitItem.FtoName }}</span>
            <em>{{ fitItem.CreatedAt | filterTime }}</em>
          </div>
          <div class="column4">
            <img src="../../src/static/images/icon/right.png"/>
          </div>
        </li>
      </ul>
    </div>

    <!--底部课程统计-->
    <bottomTab :curTab="thisTab"/>
  </div>
</template>

<script>
import axios from 'axios';
import bottomTab from '../components/bottomTab'
import {
  VipUserSelfQuery,
  PersonFtoList,
} from '../api/getApiRes.js'

let qs = require('qs');
import Global from '../Global.js'
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
// 引入提示框和title组件
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/legend')
require('echarts/lib/component/grid')

export default {
  data() {
    return {
      DuId: '',
      StdId: '',
      userMd5: '',
      thisTab: '我的运动',
      screenWidth: document.body.clientWidth + 'px',
      headImgUrl:require('../static/images/mine/header_default.png'),
      classDetail: {},//课程基础信息
      userClassInfo: {},//用户上课时的体态信息
      fitnessRecordList: {},//体测数据集
      user: {
        Name: '',
        Age: '',
        Height: '',
        Weight: '',
        StaticHr: '',
        Phone: '',
        Sex: '',
        Head: '',
      }
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'fitnessRecord') {
        let that = this;
        that.getUserInfo();//获取用户基本信息
        that.getFitnessRecord();//获取测评记录
      }
    },
  },
  mounted() {
    let that = this;
    that.getUserInfo();//获取用户基本信息
    that.getFitnessRecord();//获取测评记录
  },
  methods: {
    //返回上一页
    back() {
      this.$router.push({path: '/report'});
    },
    //获取用户基本信息
    getUserInfo() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      VipUserSelfQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.user = json.Rs;
          that.userMd5 = json.Rs.UserMd5;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取课程评分信息
    getFitnessRecord() {
      let that = this;
      let param = {
        token: localStorage.token,
        start: 1,
        tableMax: 99,
      };
      let postData = qs.stringify(param);
      PersonFtoList(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.fitnessRecordList = json.Rs;
        } else {
          that.fitnessRecordList = [];
          // that.$toast.message(json.Memo);
        }
      })
    },
    //查看体测详情
    toFitnessDetail(fitnessItem) {
      this.$router.push({
        path: '/fitnessDetail',
        query: {
          shopName: fitnessItem.ShopName,
          ftoId: fitnessItem.FtoId,
          fitName: fitnessItem.FtoName,
          fitTime: fitnessItem.CreatedAt,
          age: fitnessItem.Age,
          height: fitnessItem.Height,
          weight: fitnessItem.Weight/10

        }
      });
    }
  },
  filters: {
    hidePhone: function (val) {
      let str = String(val)
      let len = str.length;
      if (len >= 7) {
        let reg = str.slice(-8, -4)
        return str.replace(reg, "****")
      } else if (len < 7 && len >= 6) {
        let reg = str.slice(-4, -2)
        return str.replace(reg, "**")
      }
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
    fmtTime: function (value) {
      if (!value) {
        return 0
      } else {
        // js默认使用毫秒
        value = value * 1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == 'reportLessonDetail') {
        vm.getUserInfo();
      }
    });
  },
  components: {
    bottomTab
  }
}
</script>

<style scoped>
.pages {
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #F2F2F2;
}

.headerInfo {
  position: relative;
}

.headerInfo .headBack {
  height: 120px;
  position: absolute;
  z-index: 1;
}

.headerInfo .backView {
  left: 5%;
  top: 1.5rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .Back {
  width: 50px;
  height: 25px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 26px;
  color: #F4F4F4;
  display: block;
  margin-top: 60px;
  margin-left: -10px;
  background: rgba(255, 255, 255, 0.24);
  text-align: center;
}


.headerInfo .userInfo {
  left: 42%;
  top: 1.25rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .userInfo span {
  font-size: 16px;
  color: white;
  display: block;
  margin-top: 5px;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 3px;
  border: solid 2px white;
}

.info {
  background: #fff;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 120px auto 0;
}

.info ul {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 10px auto;
}

.info li {
  width: 25%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  float: left;
  text-align: center;
}

.info li:nth-child(2) {
  border-color: #e2e2e2;
}

.info li:nth-child(3) {
  border-color: #e2e2e2;
  border-left: 0;
}

.info li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #363636;
  font-size: 20px;
  text-align: center;
}

.info li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: center;
}

.fitnessRecord {
  height: 200px;
  margin-top: 10px;
}

.fitnessRecord .title {
  height: 60px;
  font-size: 16px;
  color: #E75296;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  background: #FFFFFF;
}

.fitnessRecord .recordEmpty {
  height: 200px;
  line-height: 100px;
  text-align: center;
  font-weight:normal;
  font-size: 16px;
}

.fitnessRecord .record {
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;

}

.fitnessRecord .record li {
  background: #FFFFFF;
  height: 56px;
  width: 95%;
  margin: 5px auto 0;
  border-radius: 8px;
}

.fitnessRecord .record li .column1 {
  line-height: 56px;
  font-size: 16px;
  color: white;
  width: 34px;
  height: 100%;
  border-radius: 8px 0 0 8px;
  background: #E75296;
  text-align: center;
  float: left;
}

.fitnessRecord .record li .column2 {
  line-height: 56px;
  font-size: 16px;
  width: 95px;
  height: 100%;
  text-align: center;
  float: left;
}

.fitnessRecord .record li .column3 {
  margin-left: 10px;
  padding-left: 5px;
  margin-top: 8px;
  float: left;
}

.fitnessRecord .record li .columnSplit {
  margin-top: 20px;
  height: 30px;
  margin-top: 15px;
  border-left: 1px solid #b2b2b2;
  float: left;
}

.fitnessRecord .record li .column3 span {
  display: block;
  font-size: 14px;
}

.fitnessRecord .record li .column3 em {
  font-size: 12px;
  color: #b2b2b2;
}

.fitnessRecord .record li .column4 {
  /*line-height: 56px;*/
  /*margin-right: 15px;*/
  /*color: #c4c4c4;*/
  float: right;
  /*font-size: 18px;*/
  /*background: image("../../src/static/images/icon/right.png");*/
}

.fitnessRecord .record li .column4 img {
  width: 8px;
  margin-top: 18px;
  margin-right: 20px;
  height: 16px;
}

/deep/ .mu-warning-text-color {
  float: left;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 360px) {
}

@media only screen and (max-width: 320px) {

}

@media only screen and (min-width: 641px) {

}
</style>
